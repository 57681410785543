import { createRouter, createWebHashHistory } from 'vue-router'
import menu from '@/router/menu'
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      name: 'login',
      path: '/',
      component: () => import('@/view/login'),
      meta: {
        title: '登录'
      },
    },
      ...menu
  ],
})

// 路由守卫
router.beforeEach( (to,from, next) => {
  if (!localStorage.getItem('token')) {
    // token不存在，未登录
    if (to.path === '/') {
      next()
      return
    }
    next('/')
  } else {
    // 已登录
    next()
  }
  // window.sessionStorage.setItem('menuRouter', to.path)
  // return true
})

export default router

const state = {
    type: '',
    start: '',
    end: '',
    typeLabel: ''
}
const mutations = {
    set_type (state, val) {
        state.type = val
    },
    set_start (state, val) {
        state.start = val
    },
    set_end (state, val) {
        state.end = val
    },
    set_type_label (state, val) {
        state.typeLabel = val
    }
}
const actions = {
    setType ({ commit }, val) {
        commit('set_type', val)
    },
    setStart ({ commit }, val) {
        commit('set_start', val)
    },
    setEnd ({ commit }, val) {
        commit('set_end', val)
    },
    setTypeLabel ({ commit }, val) {
        commit('set_type_label', val)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
<template>
  <div class="app-header">
    <div class="logo-left">
      <Time class="time"/>
    </div>
    <div class="logo-title link--kukuri">气体泄漏设备数据展示平台</div>
    <div class="logo-right">
      <img src="../assets/images/header/logout.png" alt="" title="切换账号" @click="clickUser">
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from 'vue';
import Time from '@/components/Time'
import { useStore } from 'vuex'
import router from "@/router";
export default defineComponent({
  name: 'HeaderPage',
  components: {
    Time
  },
  setup() {
    // 初始化的数据
    const state = reactive({
      url: require('../assets/images/login/logo.png'),
      user: '',
      organization: ''
    });
    const store = useStore()
    state.user = store.state.user.name
    state.organization = store.state.user.organization
    const clickUser = () => {
      router.push({
        path: "/"
      });
    }
    return {
      state,
      clickUser
    }
  }
})
</script>

<style lang="scss" scoped>
.app-header {
  line-height: 40px;
  background-size: 100%;
  font-size: var(----fontMedium);
  font-weight: bold;
  background: url("@/assets/images/bg/head_bg.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;

  .logo-left, .logo-right {
    flex: 1;
    z-index: 9;
    display: flex;
    position: relative;
    .time {
      width: 240px;
      height: 36px;
      position: absolute;
      left: 0;
      top: 4px;
    }
    img {
      width: 28px;
      height: 28px;
      border-radius: 28px;
      position: absolute;
      top: 6px;
      right: 10px;
      cursor: pointer;
    }
  }
  .menu-select {
    width: 180px;
    height: 32px;
    position: absolute;
    top: 4px;
    right: 234px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    letter-spacing: 2px;
    background: rgba(18, 150, 219, 0.27);
    background-size: 100% 100%;
    .menu-select-name {
      font-size: 16px;
      font-weight: 600;
      color: rgb(0, 161, 255);
    }

    .menu-select-icon {
      width: 16px;
      height: 16px;
      margin-right: -16px;
      margin-bottom: 0;
      transition: transform 0.3s;
      transform: rotate(0deg);
    }

    .menu-select-icon-reverse {
      transform: rotate(180deg);
    }
  }
  .back-home {
    width: 36px;
    height: 36px;
    background-size: 100%;
    background-position: center;
    position: absolute;
    right: 300px;
    top: 8px;
    cursor: pointer;
  }
  .logo-title {
    line-height: 70px;
    font-family: PingFangSC-Medium, PingFang SC;
    flex: 1;
    //text-indent: 15px;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 9;
    letter-spacing: 12px;
    display: inline-block;
    text-shadow: 0 0 0.1em rgba(1, 255, 255, 0.2), 0 0 0.1em rgba(1, 255, 255, 0.4);
    font-size: 36px;
    font-weight: 500;
    color: rgb(0, 161, 255);
  }
  .logo-right {
    flex: 1;
  }
  .header-menu-list {
    width: 180px;
    text-indent: 16px;
    max-height: 200px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(1, 20, 47, 0.5);
    color: rgb(0, 161, 255);
    background: rgba(18, 150, 219, 0.27);
    position: absolute;
    right: 234px;
    top: 44px;
    z-index: 999;
    overflow: auto;
    opacity: 0.9;

    &::-webkit-scrollbar {
      width: 0;
    }

    .header-menu-item {
      margin-top: 2px;
      cursor: pointer;

      .header-menu-item-title {
        font-size: 16px;
        font-weight: 200;
        color: rgb(0, 161, 255);
      }

      .header-menu-item-title-active {
        color: #00C4F2;
      }
    }
  }
}
.app-header::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 66px;
}
</style>
const state = {
    name: '',
    organization: ''
}
const mutations = {
    set_name (state, val) {
        state.name = val
    },
    set_organization (state, val) {
        state.organization = val
    }
}
const actions = {
    setName ({ commit }, val) {
        commit('set_name', val)
    },
    setOrganization ({ commit }, val) {
        commit('set_organization', val)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
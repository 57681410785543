<template>
  <div class="container">
    <div class="left">
      <el-menu
          default-active="/homePage"
          class="el-menu-vertical-demo"
          select="handleSelect"
          router
          unique-opened
          background-color="rgba(255,255,255,.1)" text-color="#fff" active-text-color="#409EFF"
      >
        <!-- 将渲染导航每一项传给子组件渲染，item代表要渲染每一项 -->
        <SubAside v-for="(item) in state.routerList" :key="item.path" :menu="item" :index="item.path" />
      </el-menu>
    </div>
    <router-view class="right"/>
  </div>
</template>

<script>
import {reactive} from "vue";
import menu from '@/router/menu'
export default {
  name: "mainIndex",
  setup() {
    const state = reactive({
      routerList: []
    })
    // 路由
    state.routerList = menu
    return {
      state
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  position: static;
  .left {
    position: absolute;
    left: 0;
    width: 240px;
    height: 100%;
    ::v-deep {
      .el-menu {
        height: 99%;
        overflow: hidden;
        border-right: solid 1px #485678;
        background: transparent !important;
      }
      .el-sub-menu__title {
        background: transparent !important;
      }
      .el-menu-item.is-active {
        background: rgba(240, 248, 255, .12);
      }
    }
  }
  .right {
    position: absolute;
    left: 240px;
    width: calc(100% - 240px);
    height: 100%;
    background-color: rgba(255,255,255,0.1);
  }
}
</style>

// 注意：引入的不再是v2中的构造函数，而是名为createApp的工厂函数
import { createApp } from 'vue'
import App from './App.vue'
import './styles/common.css'; // 引入全局样式
import './styles/breadcrumb.css'; // 引入全局面包屑样式
// 引入第三方库
import './utils/rem'
import router from './router/index'
import store from './store'
import * as echarts from 'echarts'
import 'element-plus/theme-chalk/index.css'
import ElementPlus from "element-plus"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn' // elementUI汉化包

const app = createApp(App)
import SubAside from './components/subAside.vue'
app.component('SubAside', SubAside)
app.config.globalProperties.$echarts = echarts;
app.use(store)
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(router).mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }




<template>
  <!-- 有子节点渲染这个 -->
  <el-sub-menu :index="menu.path" v-if="menu?.children">
    <template #title>
      <span>{{menu?.meta.title}}</span>
    </template>
    <!-- 递归调用本身，该组件在index.ts中全局注册了 -->
    <SubAside v-for="(item, index) in menu.children" :menu="item" :key="index" :isCollapse="isCollapse"/>
  </el-sub-menu>
  <!-- 没有子节点渲染这个 -->
  <el-menu-item  v-else  :index="menu?.path" :disabled="menu?.meta.disabled">
    <span>{{menu?.meta.title}}</span>
  </el-menu-item >
</template>

<script>
export default {
  name: "subAside",
  props: {
    menu: {
      type: Object
    },
    isCollapse: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
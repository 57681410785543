<template>
  <div class="realtime">
    <div class="time">
      {{ time }}
    </div>
    <div class="date-info">
      <div class="week">
        {{ week }}
      </div>
      <div class="date">
        {{ date }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive} from "vue";

const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']

export default defineComponent({
  name: 'realTime',
  setup() {
    const state = reactive({
      curDateTime: {
        year: '',
        month: '',
        day: '',
        hh: '',
        mm: '',
        ss: '',
        week: ''
      },
      timer: null
    })
    let time = computed(()=> {
      let { hh, mm, ss } = state.curDateTime
      return `${hh}:${mm}:${ss}`
    })
    let week = computed(()=> {
      return state.curDateTime.week
    })
    let date = computed(()=> {
      let { year, month, day } = state.curDateTime
      return `${year}-${month}-${day}`
    })
    // 页面加载完成钩子
    onMounted(() => {
      // 先初始化一次，防止出行undefined的情况，1秒后开始实时时间
      getTime()
      state.timer = setInterval(getTime,1000)
    })
    onBeforeUnmount(()=> {
      clearInterval(state.timer)
      state.timer = null
    })
    const getTime = () => {
      let now = new Date()
      state.curDateTime.year = now.getFullYear() // 年
      state.curDateTime.month = addZero(now.getMonth() + 1) // 月
      state.curDateTime.day = addZero(now.getDate()) // 日
      state.curDateTime.hh = addZero(now.getHours()) // 时
      state.curDateTime.mm = addZero(now.getMinutes()) // 分
      state.curDateTime.ss = addZero(now.getSeconds()) // 秒
      state.curDateTime.week = weeks[now.getDay()] // 星期
    }
    const addZero = (num) => {
      return num < 10 ? '0' + num : num
    }
    return {
      state, getTime, addZero,
      time, week, date
    }
  }
})
</script>

<style lang="scss" scoped>
.realtime {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .time {
    line-height: 16px;
    font-size: 32px;
    // font-family: DINPro;
    font-weight: 500;
    color: rgb(0, 161, 255);
    margin-right: 12px;
  }

  .date-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .week {
      line-height: 12px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 500;
      color: rgb(0, 161, 255);
      margin-bottom: 10px;
    }

    .date {
      line-height: 12px;
      font-size: 14px;
      // font-family: DIN Pro;
      font-weight: normal;
      color: rgb(0, 161, 255);
      margin-bottom: 4px;
    }
  }
}
</style>

import { createStore } from 'vuex'
import homePage from './modules/homePage'
import user from "@/store/modules/user";
import equip from "@/store/modules/equip";

export default createStore({
  modules: {
    homePage,
    user,
    equip
  }
})

const menu = [
    {
        name: 'homePage',
        path: '/homePage',
        component: () => import('@/view/homePage'),
        meta: {
            title: '首页', disabled: false
        },
    },
    {
        name: 'equipManage',
        path: '/equipManage',
        meta: {
            title: '设备管理'
        },
        children: [
            {
                name: 'equipState',
                path: '/equipState',
                component: () => import('@/view/equipManage/equipState'),
                meta: {
                    title: '设备状态', disabled: false
                },
            },
            {
                name: 'equipHistoryData',
                path: '/equipHistoryData',
                component: () => import('@/view/equipManage/equipHistoryData'),
                meta: {
                    title: '设备数据', disabled: false
                },
            },
            {
                name: 'comparativeAnalysis',
                path: '/comparativeAnalysis',
                component: () => import('@/view/equipManage/comparativeAnalysis'),
                meta: {
                    title: '对比分析', disabled: true
                },
            }
        ]
    },
    {
      name: 'warningManage',
      path: '/warningManage',
      component: () => import('@/view/warningManage'),
      meta: {
        title: '报警管理', disabled: true
      },
    },
    {
      name: 'factoryManage',
      path: '/factoryManage',
      component: () => import('@/view/factoryManage'),
      meta: {
        title: '工厂管理', disabled: true
      },
    },
]
export default menu

<template>
  <div class="main-screen">
    <Header class="header"/>
    <Main class="main"/>
  </div>
</template>

<script>
import Header from './view/header'
import Main from './view/main'
export default {
  name: 'App',
  components: {
    Header,
    Main
  }
}
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: 'MF LangQianNormal','Microsoft yahei', Helvetica, Arial, Simsun, sans-serif;
}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFF;
  overflow: hidden;
}
.main-screen {
  position: relative;
  width: 100%;
  height: 100%;
  background: url('./assets/images/bg/bg01.png');
  background-size: 100% 100%;
  .header {
    width: 100%;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .main {
    width: 100%;
    height: calc(100% - 80px);
    position: absolute;
    top: 80px;
    left: 0;
  }
}
</style>
